/**
 * Pet Adoption System API
 * API for user registration, login, adopter preferences, shelter pet management, and recommendations in the Pet Adoption System.
 *
 * The version of the OpenAPI document: 5.0.0
 * Contact: support@petadoption.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import MatchingRecommendationAcceptedGet200Response from '../model/MatchingRecommendationAcceptedGet200Response';
import MatchingRecommendationIdAcceptPut200Response from '../model/MatchingRecommendationIdAcceptPut200Response';

/**
* Matching service.
* @module api/MatchingApi
* @version 5.0.0
*/
export default class MatchingApi {

    /**
    * Constructs a new MatchingApi. 
    * @alias module:api/MatchingApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the matchingRecommendationAcceptedGet operation.
     * @callback module:api/MatchingApi~matchingRecommendationAcceptedGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MatchingRecommendationAcceptedGet200Response} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a list of accepted recommendations
     * @param {module:api/MatchingApi~matchingRecommendationAcceptedGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MatchingRecommendationAcceptedGet200Response}
     */
    matchingRecommendationAcceptedGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['BearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = MatchingRecommendationAcceptedGet200Response;
      return this.apiClient.callApi(
        '/matching/recommendation/accepted', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the matchingRecommendationIdAcceptPut operation.
     * @callback module:api/MatchingApi~matchingRecommendationIdAcceptPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MatchingRecommendationIdAcceptPut200Response} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Accept a recommendation and create an adoption
     * @param {String} id The ID of the recommendation to accept
     * @param {module:api/MatchingApi~matchingRecommendationIdAcceptPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MatchingRecommendationIdAcceptPut200Response}
     */
    matchingRecommendationIdAcceptPut(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling matchingRecommendationIdAcceptPut");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['BearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = MatchingRecommendationIdAcceptPut200Response;
      return this.apiClient.callApi(
        '/matching/recommendation/{id}/accept', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the matchingRecommendationIdRejectPut operation.
     * @callback module:api/MatchingApi~matchingRecommendationIdRejectPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MatchingRecommendationIdAcceptPut200Response} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Reject a recommendation
     * @param {String} id The ID of the recommendation to reject
     * @param {module:api/MatchingApi~matchingRecommendationIdRejectPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MatchingRecommendationIdAcceptPut200Response}
     */
    matchingRecommendationIdRejectPut(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling matchingRecommendationIdRejectPut");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['BearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = MatchingRecommendationIdAcceptPut200Response;
      return this.apiClient.callApi(
        '/matching/recommendation/{id}/reject', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the matchingRecommendationNextGet operation.
     * @callback module:api/MatchingApi~matchingRecommendationNextGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MatchingRecommendationAcceptedGet200Response} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get the next recommendation for the adopter
     * @param {Object} opts Optional parameters
     * @param {Number} [num = 1)] The number of recomendations to return
     * @param {module:api/MatchingApi~matchingRecommendationNextGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MatchingRecommendationAcceptedGet200Response}
     */
    matchingRecommendationNextGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'num': opts['num']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['BearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = MatchingRecommendationAcceptedGet200Response;
      return this.apiClient.callApi(
        '/matching/recommendation/next', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
