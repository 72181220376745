/**
 * Pet Adoption System API
 * API for user registration, login, adopter preferences, shelter pet management, and recommendations in the Pet Adoption System.
 *
 * The version of the OpenAPI document: 5.0.0
 * Contact: support@petadoption.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class Role.
* @enum {}
* @readonly
*/
export default class Role {
    
        /**
         * value: "adopter"
         * @const
         */
        "adopter" = "adopter";

    
        /**
         * value: "shelter"
         * @const
         */
        "shelter" = "shelter";

    

    /**
    * Returns a <code>Role</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/Role} The enum <code>Role</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

