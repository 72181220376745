/**
 * Pet Adoption System API
 * API for user registration, login, adopter preferences, shelter pet management, and recommendations in the Pet Adoption System.
 *
 * The version of the OpenAPI document: 5.0.0
 * Contact: support@petadoption.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AdoptionStatus from './AdoptionStatus';
import Pet from './Pet';
import User from './User';

/**
 * The Adoption model module.
 * @module model/Adoption
 * @version 5.0.0
 */
class Adoption {
    /**
     * Constructs a new <code>Adoption</code>.
     * @alias module:model/Adoption
     * @extends Object
     */
    constructor() { 
        
        Adoption.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Adoption</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Adoption} obj Optional instance to populate.
     * @return {module:model/Adoption} The populated <code>Adoption</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Adoption();

            ApiClient.constructFromObject(data, obj, 'String');
            

            if (data.hasOwnProperty('adoptionId')) {
                obj['adoptionId'] = ApiClient.convertToType(data['adoptionId'], 'String');
            }
            if (data.hasOwnProperty('adopterId')) {
                obj['adopterId'] = ApiClient.convertToType(data['adopterId'], 'String');
            }
            if (data.hasOwnProperty('shelterUserId')) {
                obj['shelterUserId'] = ApiClient.convertToType(data['shelterUserId'], 'String');
            }
            if (data.hasOwnProperty('pet')) {
                obj['pet'] = Pet.constructFromObject(data['pet']);
            }
            if (data.hasOwnProperty('adopter')) {
                obj['adopter'] = User.constructFromObject(data['adopter']);
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = AdoptionStatus.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Adoption</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Adoption</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['adoptionId'] && !(typeof data['adoptionId'] === 'string' || data['adoptionId'] instanceof String)) {
            throw new Error("Expected the field `adoptionId` to be a primitive type in the JSON string but got " + data['adoptionId']);
        }
        // ensure the json data is a string
        if (data['adopterId'] && !(typeof data['adopterId'] === 'string' || data['adopterId'] instanceof String)) {
            throw new Error("Expected the field `adopterId` to be a primitive type in the JSON string but got " + data['adopterId']);
        }
        // ensure the json data is a string
        if (data['shelterUserId'] && !(typeof data['shelterUserId'] === 'string' || data['shelterUserId'] instanceof String)) {
            throw new Error("Expected the field `shelterUserId` to be a primitive type in the JSON string but got " + data['shelterUserId']);
        }
        // validate the optional field `adopter`
        if (data['adopter']) { // data not null
          User.validateJSON(data['adopter']);
        }

        return true;
    }


}



/**
 * @member {String} adoptionId
 */
Adoption.prototype['adoptionId'] = undefined;

/**
 * @member {String} adopterId
 */
Adoption.prototype['adopterId'] = undefined;

/**
 * @member {String} shelterUserId
 */
Adoption.prototype['shelterUserId'] = undefined;

/**
 * @member {module:model/Pet} pet
 */
Adoption.prototype['pet'] = undefined;

/**
 * @member {module:model/User} adopter
 */
Adoption.prototype['adopter'] = undefined;

/**
 * @member {module:model/AdoptionStatus} status
 */
Adoption.prototype['status'] = undefined;

/**
 * @member {Date} date
 */
Adoption.prototype['date'] = undefined;






export default Adoption;

