/**
 * Pet Adoption System API
 * API for user registration, login, adopter preferences, shelter pet management, and recommendations in the Pet Adoption System.
 *
 * The version of the OpenAPI document: 5.0.0
 * Contact: support@petadoption.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AdoptionStoryGet200ResponseAllOfPayloadInner model module.
 * @module model/AdoptionStoryGet200ResponseAllOfPayloadInner
 * @version 5.0.0
 */
class AdoptionStoryGet200ResponseAllOfPayloadInner {
    /**
     * Constructs a new <code>AdoptionStoryGet200ResponseAllOfPayloadInner</code>.
     * @alias module:model/AdoptionStoryGet200ResponseAllOfPayloadInner
     */
    constructor() { 
        
        AdoptionStoryGet200ResponseAllOfPayloadInner.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AdoptionStoryGet200ResponseAllOfPayloadInner</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AdoptionStoryGet200ResponseAllOfPayloadInner} obj Optional instance to populate.
     * @return {module:model/AdoptionStoryGet200ResponseAllOfPayloadInner} The populated <code>AdoptionStoryGet200ResponseAllOfPayloadInner</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AdoptionStoryGet200ResponseAllOfPayloadInner();

            if (data.hasOwnProperty('author')) {
                obj['author'] = ApiClient.convertToType(data['author'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AdoptionStoryGet200ResponseAllOfPayloadInner</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AdoptionStoryGet200ResponseAllOfPayloadInner</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['author'] && !(typeof data['author'] === 'string' || data['author'] instanceof String)) {
            throw new Error("Expected the field `author` to be a primitive type in the JSON string but got " + data['author']);
        }
        // ensure the json data is a string
        if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
            throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
        }
        // ensure the json data is a string
        if (data['text'] && !(typeof data['text'] === 'string' || data['text'] instanceof String)) {
            throw new Error("Expected the field `text` to be a primitive type in the JSON string but got " + data['text']);
        }

        return true;
    }


}



/**
 * The author of the adoption story
 * @member {String} author
 */
AdoptionStoryGet200ResponseAllOfPayloadInner.prototype['author'] = undefined;

/**
 * The title of the adoption story
 * @member {String} title
 */
AdoptionStoryGet200ResponseAllOfPayloadInner.prototype['title'] = undefined;

/**
 * The content of the adoption story
 * @member {String} text
 */
AdoptionStoryGet200ResponseAllOfPayloadInner.prototype['text'] = undefined;






export default AdoptionStoryGet200ResponseAllOfPayloadInner;

