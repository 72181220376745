// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._mzAucw9xVsSmLj4JpBW {
  background-color: #b4f1f1;
  border-color: #2d767f;
  color: #1e6262;
}

._mzAucw9xVsSmLj4JpBW:hover {
  background-color: #ecfffb;
  border-color: #2d767f;
  color: #1e6262;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Support.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".support-btn {\r\n  background-color: #b4f1f1;\r\n  border-color: #2d767f;\r\n  color: #1e6262;\r\n}\r\n\r\n.support-btn:hover {\r\n  background-color: #ecfffb;\r\n  border-color: #2d767f;\r\n  color: #1e6262;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"support-btn": `_mzAucw9xVsSmLj4JpBW`
};
export default ___CSS_LOADER_EXPORT___;
