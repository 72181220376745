/**
 * Pet Adoption System API
 * API for user registration, login, adopter preferences, shelter pet management, and recommendations in the Pet Adoption System.
 *
 * The version of the OpenAPI document: 5.0.0
 * Contact: support@petadoption.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class RecommendationStatus.
* @enum {}
* @readonly
*/
export default class RecommendationStatus {
    
        /**
         * value: "new"
         * @const
         */
        "new" = "new";

    
        /**
         * value: "accepted"
         * @const
         */
        "accepted" = "accepted";

    
        /**
         * value: "rejected"
         * @const
         */
        "rejected" = "rejected";

    

    /**
    * Returns a <code>RecommendationStatus</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/RecommendationStatus} The enum <code>RecommendationStatus</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

