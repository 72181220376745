// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZiZcQl4p6HjXaXeL4rYP{
    margin: 5% 30% 5% 30%;
    text-align: center;
}

.TokRLklPpZVRafmOyXSV{
    color: #1e6262;
    min-width: 100%;
    margin-bottom: 5%;
}

.CbvaKH1W45olKM2MySng{
    border: 10px solid #1e6262;
    border-radius: 20px;
    padding: 3%;
    background-color: #b4f1f1;
    text-align: left;
    color: #1e6262;
}

.RtShL7Hjmf3_GqO4gzGg{
    font-size: 25px;
}

.yqNFoKqdOuIXVIqVLfTA{
    background-color: #ecfffb;
    border-radius: 5px;
    border: 1px solid #1e6262;
    height: 35px;
}

.WlEBC1lKixj1D1ZfwpgS{
    display: flex;
    flex-direction: column;
}

.QPOcLvzbdZd4XJ91AY1Q{
    display: flex;
    border-radius: 20px;
    background-color: #1e6262;
    border: 1px solid #1e6262;
    padding: 5px 50px 5px 50px;
    margin-left: auto;
    margin-right: auto;
    color: #ecfffb;
    transition: color .5s, background-color 0.5s;
}

.QPOcLvzbdZd4XJ91AY1Q:hover{
    color: #1e6262;
    background-color: #ecfffb;
}

.vHKKTzpBp0fzCO31kNjH{
    color: #1e6262;
    min-width: 100%;
    margin-top: 5%;
}

span:hover{
    cursor: pointer;
    text-decoration: underline;
}

`, "",{"version":3,"sources":["webpack://./src/styles/Register.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;IAC1B,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,yBAAyB;IACzB,0BAA0B;IAC1B,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,4CAA4C;AAChD;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,0BAA0B;AAC9B","sourcesContent":[".register{\r\n    margin: 5% 30% 5% 30%;\r\n    text-align: center;\r\n}\r\n\r\n.title{\r\n    color: #1e6262;\r\n    min-width: 100%;\r\n    margin-bottom: 5%;\r\n}\r\n\r\n.regform{\r\n    border: 10px solid #1e6262;\r\n    border-radius: 20px;\r\n    padding: 3%;\r\n    background-color: #b4f1f1;\r\n    text-align: left;\r\n    color: #1e6262;\r\n}\r\n\r\n.question{\r\n    font-size: 25px;\r\n}\r\n\r\n.input{\r\n    background-color: #ecfffb;\r\n    border-radius: 5px;\r\n    border: 1px solid #1e6262;\r\n    height: 35px;\r\n}\r\n\r\n.username{\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.mybtn{\r\n    display: flex;\r\n    border-radius: 20px;\r\n    background-color: #1e6262;\r\n    border: 1px solid #1e6262;\r\n    padding: 5px 50px 5px 50px;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    color: #ecfffb;\r\n    transition: color .5s, background-color 0.5s;\r\n}\r\n\r\n.mybtn:hover{\r\n    color: #1e6262;\r\n    background-color: #ecfffb;\r\n}\r\n\r\n.signin{\r\n    color: #1e6262;\r\n    min-width: 100%;\r\n    margin-top: 5%;\r\n}\r\n\r\nspan:hover{\r\n    cursor: pointer;\r\n    text-decoration: underline;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"register": `ZiZcQl4p6HjXaXeL4rYP`,
	"title": `TokRLklPpZVRafmOyXSV`,
	"regform": `CbvaKH1W45olKM2MySng`,
	"question": `RtShL7Hjmf3_GqO4gzGg`,
	"input": `yqNFoKqdOuIXVIqVLfTA`,
	"username": `WlEBC1lKixj1D1ZfwpgS`,
	"mybtn": `QPOcLvzbdZd4XJ91AY1Q`,
	"signin": `vHKKTzpBp0fzCO31kNjH`
};
export default ___CSS_LOADER_EXPORT___;
