/**
 * Pet Adoption System API
 * API for user registration, login, adopter preferences, shelter pet management, and recommendations in the Pet Adoption System.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@petadoption.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AdopterPreferencesPostRequest from '../model/AdopterPreferencesPostRequest';
import AdoptionStoryGet200ResponseInner from '../model/AdoptionStoryGet200ResponseInner';
import ApiResponse from '../model/ApiResponse';
import PetUpdateInfoPutRequest from '../model/PetUpdateInfoPutRequest';
import Recommendation from '../model/Recommendation';
import ShelterAddPetPostRequest from '../model/ShelterAddPetPostRequest';
import ShelterUpdateContactsPutRequest from '../model/ShelterUpdateContactsPutRequest';
import UserLoginPost200Response from '../model/UserLoginPost200Response';
import UserLoginPostRequest from '../model/UserLoginPostRequest';
import UserRegisterPostRequest from '../model/UserRegisterPostRequest';
import UserUpdateContactsPutRequest from '../model/UserUpdateContactsPutRequest';

/**
* Default service.
* @module api/DefaultApi
* @version 1.0.0
*/
export default class DefaultApi {

    /**
    * Constructs a new DefaultApi. 
    * @alias module:api/DefaultApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the adopterPreferencesPost operation.
     * @callback module:api/DefaultApi~adopterPreferencesPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ApiResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add a preference for pet attributes
     * @param {module:model/AdopterPreferencesPostRequest} adopterPreferencesPostRequest 
     * @param {module:api/DefaultApi~adopterPreferencesPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ApiResponse}
     */
    adopterPreferencesPost(adopterPreferencesPostRequest, callback) {
      let postBody = adopterPreferencesPostRequest;
      // verify the required parameter 'adopterPreferencesPostRequest' is set
      if (adopterPreferencesPostRequest === undefined || adopterPreferencesPostRequest === null) {
        throw new Error("Missing the required parameter 'adopterPreferencesPostRequest' when calling adopterPreferencesPost");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ApiResponse;
      return this.apiClient.callApi(
        '/adopter/preferences', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the adopterRecommendationsGet operation.
     * @callback module:api/DefaultApi~adopterRecommendationsGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Recommendation>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a list of recommended pets based on preferences
     * @param {module:api/DefaultApi~adopterRecommendationsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Recommendation>}
     */
    adopterRecommendationsGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Recommendation];
      return this.apiClient.callApi(
        '/adopter/recommendations', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the adoptionStoryGet operation.
     * @callback module:api/DefaultApi~adoptionStoryGetCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AdoptionStoryGet200ResponseInner>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieve a list of adoption stories
     * Returns a list of adoption stories with the specified number of objects.
     * @param {Object} opts Optional parameters
     * @param {Number} [N = 5)] The number of adoption story objects to return
     * @param {module:api/DefaultApi~adoptionStoryGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AdoptionStoryGet200ResponseInner>}
     */
    adoptionStoryGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'N': opts['N']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [AdoptionStoryGet200ResponseInner];
      return this.apiClient.callApi(
        '/adoptionStory', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the petUpdateInfoPut operation.
     * @callback module:api/DefaultApi~petUpdateInfoPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ApiResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update pet information
     * @param {module:model/PetUpdateInfoPutRequest} petUpdateInfoPutRequest 
     * @param {module:api/DefaultApi~petUpdateInfoPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ApiResponse}
     */
    petUpdateInfoPut(petUpdateInfoPutRequest, callback) {
      let postBody = petUpdateInfoPutRequest;
      // verify the required parameter 'petUpdateInfoPutRequest' is set
      if (petUpdateInfoPutRequest === undefined || petUpdateInfoPutRequest === null) {
        throw new Error("Missing the required parameter 'petUpdateInfoPutRequest' when calling petUpdateInfoPut");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ApiResponse;
      return this.apiClient.callApi(
        '/pet/update-info', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the recommendationIdAcceptPost operation.
     * @callback module:api/DefaultApi~recommendationIdAcceptPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ApiResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Accept a recommendation and create an adoption
     * @param {Number} id The ID of the recommendation to accept
     * @param {module:api/DefaultApi~recommendationIdAcceptPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ApiResponse}
     */
    recommendationIdAcceptPost(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling recommendationIdAcceptPost");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ApiResponse;
      return this.apiClient.callApi(
        '/recommendation/{id}/accept', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the recommendationNextGet operation.
     * @callback module:api/DefaultApi~recommendationNextGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Recommendation} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get the next recommendation for the adopter
     * @param {module:api/DefaultApi~recommendationNextGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Recommendation}
     */
    recommendationNextGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Recommendation;
      return this.apiClient.callApi(
        '/recommendation/next', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the shelterAddPetPost operation.
     * @callback module:api/DefaultApi~shelterAddPetPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ApiResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add a new pet to the shelter
     * @param {module:model/ShelterAddPetPostRequest} shelterAddPetPostRequest 
     * @param {module:api/DefaultApi~shelterAddPetPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ApiResponse}
     */
    shelterAddPetPost(shelterAddPetPostRequest, callback) {
      let postBody = shelterAddPetPostRequest;
      // verify the required parameter 'shelterAddPetPostRequest' is set
      if (shelterAddPetPostRequest === undefined || shelterAddPetPostRequest === null) {
        throw new Error("Missing the required parameter 'shelterAddPetPostRequest' when calling shelterAddPetPost");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ApiResponse;
      return this.apiClient.callApi(
        '/shelter/add-pet', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the shelterUpdateContactsPut operation.
     * @callback module:api/DefaultApi~shelterUpdateContactsPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ApiResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update shelter contact information
     * @param {module:model/ShelterUpdateContactsPutRequest} shelterUpdateContactsPutRequest 
     * @param {module:api/DefaultApi~shelterUpdateContactsPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ApiResponse}
     */
    shelterUpdateContactsPut(shelterUpdateContactsPutRequest, callback) {
      let postBody = shelterUpdateContactsPutRequest;
      // verify the required parameter 'shelterUpdateContactsPutRequest' is set
      if (shelterUpdateContactsPutRequest === undefined || shelterUpdateContactsPutRequest === null) {
        throw new Error("Missing the required parameter 'shelterUpdateContactsPutRequest' when calling shelterUpdateContactsPut");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ApiResponse;
      return this.apiClient.callApi(
        '/shelter/update-contacts', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the userLoginPost operation.
     * @callback module:api/DefaultApi~userLoginPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserLoginPost200Response} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Login a user
     * Logs in a user using email and password, and returns a JWT token.
     * @param {module:model/UserLoginPostRequest} userLoginPostRequest 
     * @param {module:api/DefaultApi~userLoginPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserLoginPost200Response}
     */
    userLoginPost(userLoginPostRequest, callback) {
      let postBody = userLoginPostRequest;
      // verify the required parameter 'userLoginPostRequest' is set
      if (userLoginPostRequest === undefined || userLoginPostRequest === null) {
        throw new Error("Missing the required parameter 'userLoginPostRequest' when calling userLoginPost");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UserLoginPost200Response;
      return this.apiClient.callApi(
        '/user/login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the userRegisterPost operation.
     * @callback module:api/DefaultApi~userRegisterPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ApiResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Register a new user
     * Registers a new user with an email, password, and account type (Pet Adopter or Pet Shelter).
     * @param {module:model/UserRegisterPostRequest} userRegisterPostRequest 
     * @param {module:api/DefaultApi~userRegisterPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ApiResponse}
     */
    userRegisterPost(userRegisterPostRequest, callback) {
      let postBody = userRegisterPostRequest;
      // verify the required parameter 'userRegisterPostRequest' is set
      if (userRegisterPostRequest === undefined || userRegisterPostRequest === null) {
        throw new Error("Missing the required parameter 'userRegisterPostRequest' when calling userRegisterPost");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ApiResponse;
      return this.apiClient.callApi(
        '/user/register', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the userUpdateContactsPut operation.
     * @callback module:api/DefaultApi~userUpdateContactsPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ApiResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update user contact information
     * @param {module:model/UserUpdateContactsPutRequest} userUpdateContactsPutRequest 
     * @param {module:api/DefaultApi~userUpdateContactsPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ApiResponse}
     */
    userUpdateContactsPut(userUpdateContactsPutRequest, callback) {
      let postBody = userUpdateContactsPutRequest;
      // verify the required parameter 'userUpdateContactsPutRequest' is set
      if (userUpdateContactsPutRequest === undefined || userUpdateContactsPutRequest === null) {
        throw new Error("Missing the required parameter 'userUpdateContactsPutRequest' when calling userUpdateContactsPut");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ApiResponse;
      return this.apiClient.callApi(
        '/user/update-contacts', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
