// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o9w7tj44sAMoot09Nlh6{
    color: #1e6262;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 3%;
    letter-spacing: 1px;
    font-weight: bolder;
}

.Em3DPIlCoyQJTSK_Isqz{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 75%;
    padding: 2% 15% 2% 15%;
    border: 10px solid #1e6262;
    border-radius: 5px;
    margin: 0 5% 5% 5%;
}

.Em3DPIlCoyQJTSK_Isqz form{
    min-width: 100%;
    text-align: left;
}

.CiUVW6chgnVTchHYO99b{
    color: #1e6262;
    margin-bottom: 2%;
    font-size: larger;
    font-weight: bold;
}

.GHCW_lg3IxABopoMmJ6w{
    color: #1e6262;
    margin-bottom: 2%;
    font-size: 25px;
    font-weight: bold;
    /* display: flex;
    justify-content: center; */
}

.gWSSFD24OsbSDjJnsKmU{
    display: flex;
    flex-direction: column;
}

.E48i2BaA00O3QCjLRlP8{
    display: flex;
    justify-content: center;
    margin-top: 5%;
}`, "",{"version":3,"sources":["webpack://./src/styles/ProfileSetup.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;IACd,sBAAsB;IACtB,0BAA0B;IAC1B,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB;8BAC0B;AAC9B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,cAAc;AAClB","sourcesContent":[".title{\r\n    color: #1e6262;\r\n    text-align: center;\r\n    margin-top: 5%;\r\n    margin-bottom: 3%;\r\n    letter-spacing: 1px;\r\n    font-weight: bolder;\r\n}\r\n\r\n.quesWrap{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    min-width: 75%;\r\n    padding: 2% 15% 2% 15%;\r\n    border: 10px solid #1e6262;\r\n    border-radius: 5px;\r\n    margin: 0 5% 5% 5%;\r\n}\r\n\r\n.quesWrap form{\r\n    min-width: 100%;\r\n    text-align: left;\r\n}\r\n\r\n.mylabel{\r\n    color: #1e6262;\r\n    margin-bottom: 2%;\r\n    font-size: larger;\r\n    font-weight: bold;\r\n}\r\n\r\n.mylabelQuestionnaire{\r\n    color: #1e6262;\r\n    margin-bottom: 2%;\r\n    font-size: 25px;\r\n    font-weight: bold;\r\n    /* display: flex;\r\n    justify-content: center; */\r\n}\r\n\r\n.photo{\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.buttonContainer{\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-top: 5%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `o9w7tj44sAMoot09Nlh6`,
	"quesWrap": `Em3DPIlCoyQJTSK_Isqz`,
	"mylabel": `CiUVW6chgnVTchHYO99b`,
	"mylabelQuestionnaire": `GHCW_lg3IxABopoMmJ6w`,
	"photo": `gWSSFD24OsbSDjJnsKmU`,
	"buttonContainer": `E48i2BaA00O3QCjLRlP8`
};
export default ___CSS_LOADER_EXPORT___;
